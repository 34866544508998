<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_payment_type") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- ****************   Start Molad-content  Modal **************** -->

    <div class="modal-body mt-3">
      <el-form
        ref="form"
        status-icon
        :model="form"
        :rules="rules"
        :label-position="'left'"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Тип Бонус">
              <el-select style="width: 100%" v-model="form.part">
                <el-option
                  label="Партнер клиника"
                  value="partner_clinics"
                ></el-option>
                <el-option
                  label="Партнер доктор"
                  value="partner_doctors"
                ></el-option>
                <el-option label="Наш Доктор" value="doctors"></el-option>
                <el-option
                  label="Процессы Л.П.Д "
                  value="laborants"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Наименование">
              <part-doctors
                v-model="form.part_id"
                @getDebt="getDebt"
                class="d-block"
                :id="form.part_id"
                :part="form.part"
              >
              </part-doctors>
              <!-- <el-select
                style="width: 100%"
                v-model="form.region"
                placeholder="Наименование"
              >
                <el-option label="User Name 1" value="shanghai"></el-option>
                <el-option label="User Name 2" value="beijing"></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Дата ">
              <el-date-picker
                type="date"
                placeholder="Дата "
                v-model="form.date"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Тип оплаты">
              <el-select
                filterable
                clearable
                :placeholder="'Тип оплаты'"
                class="d-block"
                v-model="form.payment_type_id"
              >
                <el-option
                  v-for="item in payment_types"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
       
          <el-col :span="8">
            <el-form-item prop="name" label="Сумма">
              <el-input v-model="form.price" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Комментарий">
              <el-input type="textarea" v-model="form.comment"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import partDoctors from "@/components/inventory-select/part-doctors";
export default {
  mixins: [form],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    partDoctors,
  },
  data() {
    return {
      bonus_amount: null,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      score_list: "scores/list",
      rules: "paymentBonus/rules",
      payment_types: "paymentTypes/list",
      model: "paymentBonus/model",
      columns: "paymentBonus/columns",
    }),
  },
  mounted() {
    this.loadModel();
    this.paymentTypesList();
    this.getScores();
  },
  methods: {
    ...mapActions({
      getScores: "scores/index",
      getBonusDebt: "paymentBonus/getBonusDebt",
      paymentTypesList: "paymentTypes/index",
      save: "paymentBonus/update",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
    getDebt() {
      const query = { part: this.form.part, part_id: this.form.part_id };
      this.getBonusDebt(query).then((res) => {
        this.bonus_amount = res;
      });
    },
  },
};
</script>
